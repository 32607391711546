<template>
    <section>
        Clics
    </section>
</template>

<script>
export default {
    data(){
        return {

        }
    }
}
</script>